import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Spectravo – створення незабутніх вражень
			</title>
			<meta name={"description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:title"} content={"Spectravo – створення незабутніх вражень"} />
			<meta property={"og:description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:image"} content={"https://spectravo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://spectravo.com/img/3463464.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://spectravo.com/img/3463464.png"} />
			<meta name={"msapplication-TileImage"} content={"https://spectravo.com/img/3463464.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0 250px" sm-padding="40px 0 40px 0" background="url(https://spectravo.com/img/1.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="620px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					font="--headline2"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Зв'яжіться з нами
				</Text>
				<Text font="--base" margin="0 0 12px 0">
				Ми будемо раді почути вас і обговорити, як ми можемо допомогти втілити ваше бачення події в життя.Зверніться до нас сьогодні, щоб почати планувати незабутню подію.
				</Text>
				<Text font="--lead">
					- Розташування: Черняховського 1б, Одеса, 65000
					<br />
					<br />
					- Телефон: 050 424 36 93
					<br />
					<br />
					- Email: info@spectravo.com
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});